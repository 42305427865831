.customPicker {
  text-align: center;
  font-size: 20px;
  background-color: transparent;
  color: "black";
  width: 100%;
}

.noScrollBar::-webkit-scrollbar {
  width: 0;
}

/* .filepond--root,
.filepond--root .filepond--drop-label {
  height: 1px;
  max-height: 1px;
} */

/* date range picker */
.datePicker {
  border-radius: 10px;
}

.chakra-ui-dark .datePicker {
  background-color: var(--chakra-colors-gray-600);
}

.chakra-ui-dark .datePicker .rdrDayPassive .rdrDayNumber span {
  color: var(--chakra-colors-gray-400);
}

.chakra-ui-dark .datePicker .rdrDayDisabled {
  background-color: var(--chakra-colors-gray-500);
}

.chakra-ui-dark .datePicker .rdrDayDisabled .rdrDayNumber span {
  color: var(--chakra-colors-gray-300);
}

.chakra-ui-dark .datePicker .rdrDayNumber span {
  color: white;
}

.chakra-ui-dark .datePicker .rdrWeekDay {
  color: white;
}

.chakra-ui-dark .datePicker .rdrMonthPicker select {
  color: white;
  appearance: auto;
}

.chakra-ui-dark .datePicker .rdrYearPicker select {
  color: white;
  appearance: auto;
}

.chakra-ui-dark .datePicker .rdrYearPicker select option {
  color: black;
}

.chakra-ui-dark .datePicker .rdrMonthPicker select option {
  color: black;
}

/* date time picker */
.customDateTime .rdtPicker {
  min-width: 270px;
  border-radius: 10px;
  box-shadow: var(--chakra-shadows-2xl);
  top: 35px;
  left: -10px;
  font-weight: 400;
}

.customDateTime .rdtPicker .dow {
  font-weight: 400;
}

.customDateTime input:hover {
  background: inherit;
}

.customDateTime input:focus-visible {
  outline: none;
}

.customDateTime input {
  background: inherit;
}

.customDateTime .rdtSwitch:hover {
  cursor: pointer;
  background-color: #eee;
}

.chakra-ui-dark .customDateTime .rdtPicker {
  background-color: var(--chakra-colors-gray-600);
  border-color: var(--chakra-colors-gray-300);
}

.chakra-ui-dark .customDateTime .rdtDay:hover,
.chakra-ui-dark .customDateTime .rdtTimeToggle:hover,
.chakra-ui-dark .customDateTime .rdtSwitch:hover,
.chakra-ui-dark .customDateTime .rdtBtn:hover,
.chakra-ui-dark .customDateTime .rdtNext:hover,
.chakra-ui-dark .customDateTime .rdtPrev:hover,
.chakra-ui-dark .customDateTime .rdtMonth:hover,
.chakra-ui-dark .customDateTime .rdtYear:hover {
  background-color: var(--chakra-colors-gray-700);
}

.reactGiphySearchbox-searchForm-input {
  padding-left: 10px !important;
  border-radius: 7px;
}

.chakra-ui-dark .reactGiphySearchbox-searchForm-input {
  background: var(--chakra-colors-gray-800);
  border: 1px solid rgb(74, 85, 104);
}

.editable {
  padding: 6px 10px;
}

highlight-input {
  background-color: var(--chakra-colors-twitter-50) !important;
  color: var(--chakra-colors-twitter-700) !important;
  border-radius: 4px !important;
  padding: 4px !important;
  cursor: pointer;
}

.chakra-ui-dark highlight-input {
  background-color: var(--chakra-colors-gray-700) !important;
  color: var(--chakra-colors-twitter-400) !important;
}

.suggest-highlight-input {
  color: inherit;
}

.remirror-editor {
  border: 1px solid;
  border-color: var(--chakra-colors-gray-200);
  box-shadow: none !important;
}

.remirror-editor:focus {
  box-shadow: var(--rmr-color-outline) 0px 0px 0px 0.1em !important;
  border-color: #63b3ed;
}

.chakra-collapse {
  overflow: initial !important;
}

.custom-editor-styles {
  border: none !important;
  outline: none !important;
  box-shadow: none !important;
  color: inherit !important;
  font-style: inherit !important;
  min-height: 70px;
  max-height: 300px;
  padding: 5px;
}

.custom-editor-styles:focus {
  box-shadow: none !important;
  border: none;
}

.remirror-editor .ProseMirror-selectednode {
  outline: 2px solid #0a65c1 !important;
}

.with-tooltip {
  display: inline-block;
  color: rgb(10, 101, 193);
  font-weight: var(--chakra-fontWeights-bold);
}

.highlighted {
  background: #7963d233;
}

.suggestion {
  padding: 2px 8px;
}

.suggestions {
  border: 1px solid darkgray;
  border-radius: 4px;
}

.edit-sent-msg {
  height: auto;
  padding: 0;
}

.chakra-popover__popper {
  z-index: 101 !important;
}

.dynamic-banner-filepond .filepond--panel-root {
  background-color: transparent;
  border: none;
  box-shadow: none;
}

.dynamic-banner-filepond .filepond--drop-label {
  font-size: 14px;
}

.dynamic-banner-filepond .filepond--drop-label small {
  font-size: 12px !important;
}

.upload-image-filepond.filepond--root,
.upload-image-filepond.filepond--root .filepond--drop-label {
  min-height: 120px !important;
}

.filepond--icon {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 1rem;
}
.filepond--panel-dark .filepond--drop-label {
  background-color: #1E1E1E;
  border-radius: 5px;
  color: #F2F2F2;
  display: flex;
  min-height: 120px !important;
  flex-direction: column;
  padding: 1rem;
}
.upload-image-filepond.filepond--panel-dark .filepond--drop-label {
  border: 1px solid #3b3b3b;
}

.filepond--panel-light .filepond--drop-label {
  background-color: #ECECEC;
  border-radius: 5px;
  min-height: 120px !important;
  display: flex;
  flex-direction: column;
  padding: 1rem;
}

.filepond--drop-label label {
  display: flex !important;
  flex-direction: column;
  align-items: center;
}
.filepond--panel-dark .filepond--drip {
  opacity: 1 !important;
  background-color: #1E1E1E;
}
.filepond--panel-light .filepond--icon {
  display: flex;
  justify-content: center;
  margin: 0.5rem 0;
}

.filepond--panel-dark .filepond--icon {
  display: flex;
  justify-content: center;
  margin: 0.5rem 0;
}